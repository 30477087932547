import React, { useEffect, useRef, useState } from "react";
import { AppLogo, BabyToysIcon } from "./icons";
import SoleBuddy from "../assets/sole_buddy.png";
import Carrot from "../assets/carrot.png";
import Sammy from "../assets/sammy.png";
import MarkDown from "../assets/markdown.svg";
import PrePurchase from "../assets/pre_purchase.svg";
import Amazon from "../assets/amazon.png";
import PlushDogToy from "../assets/plash_dog_toys.svg";
import FetchDogToy from "../assets/fetch_toys.svg";
import WebSiteButton from "../assets/website_button.png";
import WhimsicalWhale from "../assets/whimsical-whaley.png";
import Lovebugs from "../assets/lemony-lovebugs.png";
import { BASE_URL, AMAZON_STORE_URL } from "../utils";
import { track } from "@vercel/analytics";

const trendingProducts = [
  {
    name: "Sole Buddy",
    link: `${BASE_URL}products/sole-buddy-shoe-plush-dog-toy?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb}`,
    image: SoleBuddy,
  },
  {
    name: "Squeaky Sammy",
    link: `${BASE_URL}products/squeaky-sammy-premium-plush-dog-toy?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`,
    image: Sammy,
  },
  {
    name: "Snuggle Carrots",
    link: `${BASE_URL}products/cottontail-carrots-plush-dog-toy?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`,
    image: Carrot,
  },

  {
    name: "Whimsical Whaley",
    link: `${BASE_URL}products/whimsical-whaley-denim-plush-dog-toy?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`,
    image: WhimsicalWhale,
  },
  {
    name: "Lemony Lovebugs",
    link: `${BASE_URL}products/lemony-lovebugs-squeaky-dog-toy?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`,
    image: Lovebugs,
  },
];

const PetStoreApp = () => {
  const scrollContainerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const amazonLink = AMAZON_STORE_URL;
  const fallbackURL = `${amazonLink}?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`;

  const checkScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  const openAmazonLink = (amazonLink) => {
    const intentURL = `intent://${amazonLink.replace(
      "https://",
      ""
    )}#Intent;package=in.amazon.mShop.android.shopping;scheme=https;end`;

    window.location.href = /Android/i.test(navigator.userAgent)
      ? intentURL
      : fallbackURL;
  };
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", checkScrollButtons);
      checkScrollButtons();

      return () =>
        scrollContainer.removeEventListener("scroll", checkScrollButtons);
    }
  }, []);
  return (
    <div className="max-w-full mx-auto p-4 md:max-w-[400px] lg:max-w-[400px]">
      {/* Logo */}
      <div className="flex justify-center mb-4">
        <div className="w-15 h-15">
          <AppLogo />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between mb-6">
        <button
          className="rounded-lg flex-1 mr-2 text-left cursor-pointer"
          onClick={() => {
            track("petsandpeople website");
            window.location.href = `${BASE_URL}?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`;
          }}
        >
          <img
            src={WebSiteButton}
            alt="amazon button"
            className="w-full object-cover"
          />
        </button>
        <button
          className=" rounded-lg flex-1 ml-2 cursor-pointer"
          onClick={() => {
            track("amazon");
            openAmazonLink(AMAZON_STORE_URL);
          }}
        >
          <img
            src={Amazon}
            alt="amazon button"
            className="w-full object-cover"
          />
        </button>
      </div>

      {/* Categories */}
      <h2 className="text-xl font-bold mb-4">Toy Categories</h2>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div
          className="text-center cursor-pointer"
          onClick={() => {
            track("plush dog toys");
            window.location.href = `${BASE_URL}collections/plush-dog-toys?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`;
          }}
        >
          <div className="bg-white rounded-lg  w-27 h-27 mb-2 mx-auto grid place-content-center shadow">
            <img
              src={PlushDogToy}
              alt="plush-dog-toys"
              className="w-full h-28 object-cover filter blur-md transition duration-500 ease-out"
              onLoad={(e) => e.target.classList.remove("blur-md")}
            />
          </div>
          <span className="text-sm">Plush Dog Toys</span>
        </div>
        <div
          className="text-center cursor-pointer"
          onClick={() => {
            track("fetch dog toys");
            window.location.href = `${BASE_URL}collections/fetch-dog-toys?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`;
          }}
        >
          <div className="bg-white rounded-xl w-28 h-28 mb-2 mx-auto grid place-content-center shadow">
            <img
              src={FetchDogToy}
              alt="plush-dog-toys"
              className="w-28 h-28 object-cover filter blur-md transition duration-500 ease-out"
              onLoad={(e) => e.target.classList.remove("blur-md")}
            />
          </div>
          <span className="text-sm">Fetch Dog Toys</span>
        </div>
        <div
          className="text-center cursor-pointer"
          onClick={() => {
            track("baby toys");
            window.location.href = `${BASE_URL}collections/people?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`;
          }}
        >
          <div className="bg-white rounded-lg w-28 h-28 mb-2 mx-auto grid place-content-center shadow">
            <BabyToysIcon />
          </div>
          <span className="text-sm">Baby Toys</span>
        </div>
      </div>

      {/* Best Sellers */}
      <h2 className="text-xl font-bold mb-4">Trending Products</h2>

      <div className="relative w-full mb-6">
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto scrollbar-hide snap-x snap-mandatory scroll-smooth gap-4 px-4"
          style={{
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {trendingProducts.map((item, index) => (
            <div
              key={item.name || index}
              className="flex-none w-28 snap-start"
              onClick={() => {
                track(item.name);
                if (item.link) window.location.href = item.link;
              }}
            >
              <div className="bg-white p-4 rounded-lg mb-2 h-28 cursor-pointer shadow  transition-shadow duration-300">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full object-cover filter blur-md transition duration-500 ease-out rounded-md"
                  onLoad={(e) => e.target.classList.remove("blur-md")}
                />
              </div>
              <div className="text-center">
                <span className="text-sm block font-medium">{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Product Highlights */}

      <div className="grid grid-cols-2 gap-4">
        <div
          className="rounded-lg"
          onClick={() => {
            track("markdowns");
            window
              .open(
                `${BASE_URL}/collections/markdowns?utm_source=Organic&utm_medium=Instagram&utm_campaign=Instaweb`,
                "_blank"
              )
              .focus();
          }}
        >
          <h2 className="text-xl font-bold  mb-4">Markdowns</h2>
          <div className=" w-full mb-2 rounded-lg cursor-pointer">
            <img
              src={MarkDown}
              alt="carrot"
              className="w-full h-auto object-cover rounded-lg filter blur-md transition duration-500 ease-out"
              onLoad={(e) => e.target.classList.remove("blur-md")}
            />
          </div>
          <span className="text-sm block text-center">Last few left</span>
        </div>
        <div
          className="rounded-lg"
          // onClick={() =>
          //   window
          //     .open(
          //       `${BASE_URL}products/fidos-fashion-statement-crochet-bandana-small-dogs?utm_source=Insta_website&utm_medium=instagram%2Cwebsite%2CFidoFashionStatement%2CShihTzu&utm_campaign=Insta_FidoFashionStatement&utm_co&utm_term=instawebFidoFashionStatement`,
          //       "_blank"
          //     )
          //     .focus()
          // }
        >
          <h2 className="text-xl font-bold mb-4">Pre-purchase</h2>
          <div className=" w-full rounded mb-2 cursor-pointer">
            <img
              src={PrePurchase}
              alt="carrot"
              className="w-full h-auto object-cover rounded-lg filter blur-md transition duration-500 ease-out"
              onLoad={(e) => e.target.classList.remove("blur-md")}
            />
          </div>
          <span className="text-sm block text-center">
            Buy Before Anyone Does
          </span>
        </div>
      </div>
    </div>
  );
};

export default PetStoreApp;
