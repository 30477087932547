export const AppLogo = () => {
  return (
    <svg
      width="110"
      height="107"
      viewBox="0 0 110 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_160_235)">
        <mask
          id="mask0_160_235"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="110"
          height="107"
        >
          <path d="M110 0H0V107H110V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_160_235)">
          <path
            d="M55 107C40.3387 107 26.5459 101.456 16.16 91.3765C5.75572 81.285 0.0183938 67.856 4.41612e-05 53.5699C-0.0183055 39.2777 5.68232 25.8366 16.0621 15.7208C26.4358 5.59894 40.2408 0.0182376 54.9328 0H55.0062C69.6736 0 83.4603 5.54423 93.8462 15.6235C104.25 25.715 109.988 39.144 110.006 53.4362C110.025 67.7284 104.324 81.1695 93.9441 91.2853C83.5704 101.401 69.7654 106.988 55.0734 107.006H55V107ZM55 1.13073H54.9328C40.5528 1.14897 27.0413 6.61417 16.8817 16.5172C6.72825 26.4202 1.14384 39.5756 1.16219 53.5638C1.18054 67.5521 6.79553 80.6953 16.9796 90.574C27.1453 100.434 40.6445 105.863 55 105.863H55.0734C69.4534 105.845 82.9649 100.38 93.1245 90.4767C103.278 80.5737 108.862 67.4123 108.844 53.424C108.826 39.4358 103.211 26.2925 93.0266 16.4138C82.8548 6.55946 69.3556 1.13073 55 1.13073Z"
            fill="#D95A79"
          />
        </g>
        <path
          d="M14.276 48.4026C14.3066 48.2203 14.3617 47.7522 14.3372 47.6245C14.2944 47.3692 14.1231 47.2719 13.8968 47.2233L8.26348 46.0925C8.03717 46.05 7.85367 46.0621 7.70075 46.2932C7.59066 46.4695 7.48056 46.8646 7.42551 47.0287L7.35211 47.0166L8.11668 43.4177C8.47756 41.7216 9.21154 40.986 10.3982 41.2292C11.9334 41.5392 12.2698 43.1137 11.9518 44.6335L11.8539 45.0834L14.2393 45.5637C14.4657 45.6062 14.6553 45.5637 14.8021 45.363C14.8816 45.2536 14.9978 44.8949 15.0712 44.6579L15.1446 44.67L14.3494 48.4148L14.276 48.4026ZM11.7621 44.8706C11.9946 43.7824 11.1994 43.2414 10.2697 43.0529C9.23601 42.8463 8.34299 43.0165 8.13503 43.9952L8.06775 44.3113L11.7254 45.0469L11.7621 44.8645V44.8706Z"
          fill="#D95A79"
        />
        <path
          d="M15.7134 41.8432C15.799 41.5696 15.8847 41.2839 15.8908 41.1562C15.903 40.9009 15.7746 40.7854 15.5605 40.7003L10.1596 38.6395C9.94551 38.5604 9.73754 38.5422 9.56628 38.7428C9.45618 38.8765 9.32162 39.1319 9.2054 39.3568L9.13812 39.3325L11.3584 33.8308L13.1016 34.4934L13.0588 34.5968C12.5512 34.5664 11.2239 34.8339 10.9486 35.3081C10.8263 35.5269 10.6917 35.8126 10.5694 36.1044L10.2697 36.8522L13.2974 38.0072L13.5482 37.3932C13.7561 36.8704 13.6766 36.6212 13.6032 36.4509C13.4809 36.1713 13.016 35.7336 12.7652 35.5938L12.8081 35.4904L15.585 36.5482L15.5421 36.6516C15.2241 36.5725 14.5268 36.6151 14.2821 36.7063C14.0803 36.7853 13.8662 36.9312 13.6644 37.4297L13.4136 38.0437L15.8541 38.9799C16.5636 39.2535 16.741 39.1562 17.0835 38.2929C17.2915 37.7701 17.5056 37.2534 17.3588 36.9373C17.1263 36.4023 16.4046 35.5147 16.1232 35.1865L16.1599 35.1014L18.1417 36.0011L15.7746 41.8614L15.7073 41.8371L15.7134 41.8432Z"
          fill="#D95A79"
        />
        <path
          d="M18.8879 34.621C18.9735 34.469 19.206 34.0131 19.2304 33.8733C19.2733 33.624 19.1387 33.466 18.943 33.3505L13.5359 30.1771L13.3953 30.402C13.2729 30.6027 13.1384 30.858 13.0527 31.0525C12.8815 31.4477 13.591 32.6513 13.909 33.0039L13.8601 33.083L12.1964 32.1042L15.5727 26.6633L17.2364 27.6421L17.1875 27.7211C16.7165 27.6117 15.3097 27.5448 15.0223 27.8852C14.8816 28.0494 14.7164 28.2743 14.588 28.4871L14.4412 28.7242L19.8299 31.8914C20.0256 32.0069 20.2274 32.0313 20.4415 31.8914C20.5516 31.8124 20.8636 31.4051 20.9859 31.247L21.0471 31.2835L18.9491 34.6636L18.8879 34.6271V34.621Z"
          fill="#D95A79"
        />
        <path
          d="M22.0196 29.2895L22.1786 29.8184L22.0991 29.9096L20.0439 28.3047L20.1112 28.2257C21.518 28.7181 22.9982 28.7971 23.7077 27.9764C24.3316 27.2591 24.0625 26.5113 23.5303 26.0736C23.0594 25.6846 22.4171 25.715 21.7076 26.1223C21.2917 26.3654 20.8024 26.6755 20.4904 26.8275C19.628 27.253 18.79 27.0706 18.1172 26.5174C17.2548 25.8061 17.1141 24.493 18.1172 23.3258C18.7227 22.6267 19.3772 22.2559 19.7748 22.1282L19.6525 21.6844L19.732 21.5933L21.6526 23.1191L21.5853 23.1982C20.2763 22.724 19.0041 22.5173 18.2823 23.3441C17.793 23.9094 17.9765 24.5964 18.4597 24.9976C18.9307 25.3867 19.4934 25.4475 20.3192 24.9794C20.6984 24.7605 21.2122 24.4201 21.5425 24.2681C22.3315 23.879 23.0838 23.8304 23.934 24.5295C24.9066 25.332 24.931 26.7606 23.8729 27.9703C23.09 28.8701 22.411 29.1497 22.0073 29.3017L22.0196 29.2895Z"
          fill="#D95A79"
        />
        <path
          d="M27.3043 23.9216C27.445 23.794 27.6162 23.6785 27.6896 23.49C27.7997 23.2104 27.7569 22.8821 27.6713 22.5113C27.4817 21.6723 26.2951 17.0278 26.032 16.1159L26.503 15.7755C27.6346 16.4503 32.0201 19.1859 32.4422 19.4291C32.6379 19.5446 32.7969 19.6176 33.0722 19.5689C33.2557 19.5385 33.4392 19.4291 33.5921 19.344L33.6349 19.3987L30.5461 21.648L30.5032 21.5933C30.7663 21.3623 31.3412 20.882 31.1822 20.6753C31.1332 20.6085 31.066 20.5598 31.017 20.5294L29.3044 19.4656L27.4388 20.8273C27.4878 20.9915 27.812 22.1404 27.8548 22.2681C27.9404 22.5356 28.0383 22.7605 28.1178 22.8639C28.338 23.1496 29.0414 22.6207 29.3472 22.4383L29.39 22.493L27.3471 23.9824L27.3043 23.9277V23.9216ZM29.127 19.3501L26.6682 17.8546L27.3899 20.6206L29.1331 19.3501H29.127Z"
          fill="#D95A79"
        />
        <path
          d="M32.173 13.6357L34.0753 17.3865C34.4423 18.11 34.7236 18.4139 35.0172 18.4869C35.2436 18.5355 35.7879 18.2741 35.9531 18.2194L35.9837 18.2863L33.5065 19.4717L33.4759 19.4048C33.6104 19.3076 34.1059 19.1252 34.2466 18.8516C34.3811 18.5902 34.2649 18.1525 33.9101 17.4595L32.0629 13.8181C31.9223 13.5384 31.7143 13.3378 31.4085 13.2405C31.1149 13.1433 30.7601 13.2405 30.3625 13.3378L30.3197 13.2527L32.7235 12.0976L38.1306 14.7299L36.7482 12.0125C36.3935 11.3073 36.0876 10.9912 35.794 10.9183C35.58 10.8636 35.005 11.1067 34.8337 11.1614L34.8032 11.1007L37.3048 9.89697L37.3354 9.95776C37.2009 10.055 36.7115 10.2678 36.5708 10.5414C36.4424 10.7967 36.5586 11.2526 36.9011 11.9274L39.3233 16.7057L38.9135 16.9063L32.1669 13.6357H32.173Z"
          fill="#D95A79"
        />
        <path
          d="M40.7056 15.9822C40.9809 15.891 41.2684 15.7816 41.3785 15.7086C41.5986 15.5688 41.617 15.3925 41.5558 15.1797L40.045 9.72669C39.9839 9.50784 39.8738 9.33762 39.6108 9.32546C39.4334 9.31938 39.1459 9.36194 38.889 9.41057L38.8707 9.3437L42.3143 8.44398C43.7578 8.06707 46.339 8.15218 47.0791 10.8392C47.7152 13.131 46.0637 14.6569 44.0392 15.1858L40.724 16.0551L40.7056 15.9883V15.9822ZM45.1891 11.3255C44.6386 9.3437 43.507 8.24944 42.2103 8.58988C42.0268 8.63851 41.7699 8.71754 41.5864 8.77226L43.2012 14.6144C43.3174 15.0399 43.666 15.1676 44.1859 15.0338C45.5499 14.6752 45.7273 13.2952 45.1829 11.3255H45.1891Z"
          fill="#D95A79"
        />
        <path
          d="M51.0609 13.7269C51.2505 13.7147 51.7276 13.6661 51.8561 13.6114C52.1069 13.5141 52.1619 13.3317 52.1619 13.1068L52.0579 7.51393C52.0579 7.289 51.9968 7.11878 51.7338 7.02152C51.5319 6.95465 51.116 6.94249 50.9386 6.92425V6.8513L54.7125 6.78443C56.4924 6.75403 57.3916 7.289 57.4099 8.46837C57.4405 9.99425 55.9358 10.663 54.3333 10.6873H53.8623L53.9051 13.0642C53.9051 13.2892 53.9969 13.4594 54.2293 13.5567C54.3578 13.6053 54.7431 13.6418 54.9939 13.6539V13.7269L51.067 13.7937V13.7208L51.0609 13.7269ZM54.0458 10.5596C55.1835 10.5414 55.5566 9.66597 55.5382 8.74193C55.5199 7.71455 55.1468 6.90601 54.1253 6.92425H53.7889L53.8562 10.5596H54.0458Z"
          fill="#D95A79"
        />
        <path
          d="M57.9604 13.6478C58.254 13.6722 58.5598 13.6843 58.6883 13.66C58.9513 13.6174 59.0369 13.4655 59.0675 13.2405L59.9299 7.65983C59.9666 7.44098 59.9299 7.24036 59.6975 7.12486C59.5385 7.05191 59.2571 6.97896 59.0002 6.91817L59.0125 6.84521L65.0189 7.72062L64.7437 9.52006L64.6275 9.50183C64.5418 9.01549 63.9791 7.81181 63.4408 7.65983C63.1962 7.59296 62.8781 7.52609 62.5601 7.47745L61.7404 7.35587L61.2572 10.4867L61.9301 10.5839C62.4989 10.669 62.7313 10.5353 62.8842 10.4259C63.135 10.2496 63.4714 9.70852 63.5571 9.44103L63.6733 9.45927L63.2329 12.3287L63.1167 12.3104C63.1228 11.9943 62.9271 11.3377 62.7803 11.1311C62.6579 10.9608 62.4622 10.7845 61.9178 10.7055L61.245 10.6082L60.8535 13.1311C60.7434 13.8667 60.878 14.0065 61.82 14.1463C62.3888 14.2314 62.9576 14.3105 63.2451 14.1099C63.7283 13.7694 64.4562 12.8879 64.7253 12.5475L64.8171 12.5597L64.3583 14.6387L57.9604 13.7026L57.9726 13.6296L57.9604 13.6478Z"
          fill="#D95A79"
        />
        <path
          d="M66.3523 14.3834C65.9242 13.5809 65.8324 12.6022 66.2055 11.5627C66.6214 10.4137 67.3799 9.65379 68.2974 9.28903C69.1231 8.96076 70.0712 8.9486 71.007 9.26472C71.9367 9.58084 72.7013 10.1584 73.1417 10.9426C73.5943 11.7511 73.7166 12.7542 73.3191 13.8606C72.9337 14.9245 72.1997 15.7391 71.2456 16.1281C70.4259 16.4625 69.5146 16.4625 68.4931 16.116C67.545 15.7938 66.7805 15.1859 66.3462 14.3834H66.3523ZM71.4596 13.2588C72.0713 11.5687 72.2731 9.83008 70.9703 9.3863C69.8449 9.00331 68.8417 10.0368 68.0772 12.1523C67.4105 14.0065 67.3432 15.5992 68.5787 16.0187C69.802 16.4382 70.7685 15.1676 71.4535 13.2588H71.4596Z"
          fill="#D95A79"
        />
        <path
          d="M72.6462 17.3987C72.8175 17.4838 73.2518 17.6783 73.3863 17.6965C73.6494 17.7391 73.8023 17.6114 73.9124 17.4169L76.7749 12.5657C76.8911 12.3712 76.9339 12.1949 76.7566 11.9821C76.622 11.824 76.2672 11.6052 76.1266 11.5018L76.1633 11.441L79.4356 13.2709C80.977 14.1341 81.4663 15.0399 80.8608 16.0612C80.0778 17.3865 78.4386 17.2041 77.0502 16.4321L76.6403 16.2071L75.4293 18.2619C75.3131 18.4564 75.3008 18.6449 75.4476 18.8455C75.5333 18.9489 75.8452 19.1738 76.0532 19.3136L76.0165 19.3744L72.6034 17.4716L72.6401 17.4108L72.6462 17.3987ZM76.8789 16.1707C77.8698 16.7239 78.6466 16.1585 79.1176 15.356C79.6436 14.4624 79.7475 13.587 78.8607 13.0885L78.5732 12.9244L76.7137 16.0734L76.8789 16.1646V16.1707Z"
          fill="#D95A79"
        />
        <path
          d="M78.5487 20.7483C78.7016 20.8394 79.0931 21.1312 79.2215 21.1677C79.4846 21.2468 79.6497 21.1434 79.7965 20.9671L83.442 16.6387C83.5888 16.4685 83.6622 16.2983 83.5215 16.0612C83.4175 15.8788 83.1056 15.6113 82.9771 15.4837L83.026 15.429L86.0598 17.8485L86.0109 17.9032C85.8519 17.7998 85.5828 17.5628 85.3931 17.4898C85.1485 17.3986 84.965 17.502 84.8182 17.6722L81.3501 21.7878C80.8302 22.4018 80.9158 22.572 81.6376 23.1495C82.0413 23.4717 82.4511 23.721 82.7324 23.6967C83.234 23.648 84.3533 23.2833 84.8916 23.0644L84.9589 23.1131L83.4236 24.7301L78.4998 20.803L78.5487 20.7483Z"
          fill="#D95A79"
        />
        <path
          d="M83.8028 24.9916C84.0108 25.1922 84.231 25.3928 84.3472 25.4597C84.5735 25.5934 84.7448 25.5265 84.916 25.3806L89.2955 21.6723C89.4668 21.5264 89.5769 21.3501 89.4729 21.113C89.4056 20.955 89.2343 20.724 89.0753 20.5173L89.1303 20.4686L93.1183 24.9247L91.7054 26.1223L91.632 26.0372C91.8889 25.6116 92.262 24.3411 91.9562 23.8912C91.8155 23.6845 91.6198 23.4353 91.4057 23.1982L90.8613 22.5903L88.4086 24.6694L88.8551 25.1679C89.2343 25.5934 89.4973 25.6359 89.687 25.6481C89.9989 25.6663 90.6105 25.4718 90.8491 25.3198L90.9225 25.4049L88.6716 27.3077L88.5982 27.2226C88.8184 26.9855 89.1059 26.3655 89.1303 26.1101C89.1548 25.9034 89.1181 25.6481 88.7572 25.2408L88.3107 24.7423L86.329 26.4202C85.754 26.9065 85.7601 27.101 86.384 27.8001C86.7632 28.2196 87.1425 28.6452 87.4911 28.6695C88.0905 28.7181 89.2282 28.5053 89.6564 28.4141L89.7175 28.481L87.9804 29.7759L83.7294 25.028L83.7845 24.9794L83.8028 24.9916Z"
          fill="#D95A79"
        />
        <path
          d="M92.8553 30.7304C94.8677 29.703 96.9718 30.3535 97.9749 32.2077C98.3541 32.9128 98.5131 33.691 98.5131 34.1956L98.9963 34.2807L99.0514 34.3779L96.7026 35.6242L96.6415 35.5147C97.9565 34.232 98.3052 33.1013 97.877 32.311C97.2715 31.1864 95.6873 31.4174 93.9135 32.3232C91.9807 33.308 91.1549 34.3962 91.8155 35.6181C92.3171 36.5482 93.2712 36.9677 94.4395 36.9555V37.0771C93.2529 37.1804 91.9868 36.7671 91.2834 35.4539C90.3598 33.7396 90.8185 31.7639 92.8553 30.7243V30.7304Z"
          fill="#D95A79"
        />
        <path
          d="M94.1214 39.1743C94.5313 38.3597 95.2897 37.7092 96.3723 37.387C97.5712 37.0284 98.6477 37.1803 99.4918 37.6788C100.256 38.1287 100.825 38.8643 101.119 39.7883C101.406 40.7002 101.388 41.6425 100.996 42.451C100.593 43.2839 99.8343 43.9769 98.6844 44.3173C97.5773 44.6456 96.4641 44.5544 95.5772 44.0377C94.8126 43.5939 94.2744 42.8826 93.9563 41.8735C93.6627 40.9373 93.7116 39.9768 94.1214 39.1682V39.1743ZM98.0789 42.5118C99.8404 41.989 101.4 41.1197 100.996 39.837C100.648 38.7305 99.1921 38.5542 96.9901 39.2108C95.0634 39.7822 93.6994 40.682 94.0848 41.8917C94.464 43.0954 96.0849 43.1076 98.0727 42.5118H98.0789Z"
          fill="#D95A79"
        />
        <path
          d="M95.987 46.0134C96.5008 45.9283 96.9656 46.2809 97.0452 46.7733C97.1308 47.2657 96.8066 47.7399 96.2867 47.819C95.7729 47.9041 95.3081 47.5515 95.2285 47.0591C95.1429 46.5666 95.4671 46.0925 95.987 46.0074V46.0134Z"
          fill="#D95A79"
        />
        <path
          d="M95.7668 58.5731C95.7423 58.7555 95.6812 59.2236 95.7056 59.3512C95.7485 59.6066 95.9197 59.7038 96.146 59.7525L101.779 60.8832C102.006 60.9257 102.189 60.9136 102.342 60.6826C102.452 60.5063 102.556 60.1111 102.617 59.947L102.691 59.9592L101.926 63.558C101.565 65.2541 100.837 65.9897 99.6447 65.7526C98.1094 65.4426 97.7669 63.8742 98.0911 62.3483L98.189 61.8984L95.8035 61.4182C95.5772 61.3756 95.3876 61.4182 95.2408 61.6188C95.1613 61.7282 95.0451 62.0869 94.9717 62.324L94.8983 62.3118L95.6934 58.567L95.7668 58.5792V58.5731ZM98.2868 62.099C98.0544 63.1872 98.8496 63.7283 99.7793 63.9167C100.813 64.1234 101.706 63.9471 101.914 62.9744L101.981 62.6583L98.3235 61.9288L98.2868 62.1051V62.099Z"
          fill="#D95A79"
        />
        <path
          d="M94.3355 65.1326C94.2499 65.4062 94.1643 65.6919 94.1582 65.8195C94.1459 66.0749 94.2805 66.1904 94.4946 66.2755L99.8955 68.3363C100.11 68.4154 100.318 68.4336 100.489 68.233C100.599 68.0992 100.733 67.8439 100.85 67.619L100.917 67.6433L98.7027 73.145L96.9595 72.4823L97.0023 72.379C97.51 72.4094 98.8373 72.1419 99.1125 71.6617C99.2349 71.4428 99.3695 71.1571 99.4918 70.8653L99.7915 70.1175L96.7638 68.9625L96.513 69.5765C96.3051 70.0993 96.3846 70.3485 96.458 70.5188C96.5803 70.7984 97.0452 71.2361 97.2959 71.3699L97.2531 71.4732L94.4762 70.4154L94.519 70.3121C94.8371 70.3911 95.5344 70.3485 95.779 70.2574C95.9809 70.1783 96.195 70.0324 96.3968 69.5339L96.6415 68.9199L94.201 67.9898C93.4914 67.7163 93.3141 67.8196 92.9715 68.6768C92.7636 69.1996 92.5495 69.7163 92.6963 70.0324C92.9287 70.5674 93.6505 71.455 93.9318 71.7832L93.8951 71.8683L91.9073 70.9686L94.2682 65.1083L94.3355 65.1326Z"
          fill="#D95A79"
        />
        <path
          d="M91.1549 72.3729C91.0754 72.5249 90.8369 72.9808 90.8124 73.1206C90.7696 73.3699 90.9042 73.528 91.0999 73.6435L96.5069 76.8168L96.6476 76.5919C96.7699 76.3913 96.9045 76.1359 96.9901 75.9353C97.1614 75.5402 96.4519 74.3365 96.1338 73.9839L96.1827 73.9049L97.8464 74.8836L94.4701 80.3306L92.8064 79.3518L92.8553 79.2728C93.3263 79.3822 94.7392 79.4491 95.0206 79.1087C95.1613 78.9384 95.3264 78.7196 95.4549 78.5068L95.6017 78.2697L90.213 75.1085C90.0173 74.993 89.8093 74.9687 89.6013 75.1085C89.4912 75.1876 89.1793 75.5949 89.057 75.7529L88.9958 75.7165L91.0938 72.3364L91.1549 72.3729Z"
          fill="#D95A79"
        />
        <path
          d="M88.0355 77.6861L87.8765 77.1572L87.956 77.066L90.0111 78.671L89.9439 78.75C88.5371 78.2576 87.0568 78.1785 86.3473 79.0053C85.7296 79.7227 85.9926 80.4704 86.5247 80.9081C86.9957 81.2972 87.6379 81.2607 88.3474 80.8595C88.7634 80.6163 89.2466 80.3063 89.5646 80.1543C90.4271 79.7287 91.265 79.9111 91.9379 80.4643C92.8003 81.1695 92.9471 82.4887 91.9379 83.6559C91.3323 84.355 90.684 84.7258 90.2864 84.8535L90.4087 85.2973L90.3292 85.3885L88.4086 83.8687L88.4759 83.7896C89.7848 84.2638 91.0571 84.4705 91.7788 83.6377C92.2681 83.0723 92.0847 82.3853 91.5953 81.9841C91.1244 81.595 90.5616 81.5343 89.7359 82.0024C89.3567 82.2212 88.8429 82.5616 88.5126 82.7197C87.7236 83.1088 86.9712 83.1574 86.121 82.4644C85.1485 81.6619 85.124 80.2394 86.1761 79.0235C86.959 78.1238 87.6379 77.8442 88.0416 77.6922L88.0355 77.6861Z"
          fill="#D95A79"
        />
        <path
          d="M82.7447 83.0722C82.604 83.1999 82.4327 83.3154 82.3593 83.5039C82.2492 83.7835 82.292 84.1118 82.3777 84.4826C82.5673 85.3216 83.7539 89.96 84.0169 90.8779L83.5459 91.2184C82.4144 90.5436 78.0288 87.8079 77.6068 87.5648C77.4171 87.4493 77.252 87.3763 76.9768 87.425C76.7933 87.4553 76.6098 87.5648 76.4568 87.6499L76.414 87.5952L79.5029 85.3398L79.5457 85.3945C79.2827 85.6255 78.7077 86.1058 78.8668 86.3125C78.9157 86.3793 78.983 86.428 79.0319 86.4584L80.7445 87.5222L82.6101 86.1605C82.5612 85.9963 82.237 84.8474 82.1942 84.7197C82.1085 84.4522 82.0107 84.2273 81.9312 84.124C81.711 83.8382 81.0076 84.3671 80.7017 84.5495L80.6589 84.4948L82.7018 83.0054L82.7447 83.0601V83.0722ZM80.9219 87.6438L83.3808 89.1393L82.659 86.3733L80.9219 87.6438Z"
          fill="#D95A79"
        />
        <path
          d="M77.8881 93.3462L75.9859 89.6014C75.6189 88.878 75.3375 88.574 75.0439 88.501C74.8176 88.4524 74.2794 88.7138 74.1081 88.7685L74.0775 88.7017L76.5486 87.5101L76.5792 87.577C76.4446 87.6743 75.9492 87.8566 75.8085 88.1302C75.6739 88.3916 75.7902 88.8293 76.1449 89.5223L77.9921 93.1577C78.1328 93.4374 78.3408 93.638 78.6466 93.7352C78.9402 93.8325 79.2949 93.7352 79.6925 93.638L79.7353 93.7231L77.3376 94.8781L71.9367 92.2519L73.3191 94.9632C73.6738 95.6623 73.9797 95.9845 74.2732 96.0575C74.4873 96.1122 75.0623 95.869 75.2335 95.8143L75.2641 95.8812L72.7625 97.0849L72.7319 97.018C72.8664 96.9207 73.3558 96.708 73.4964 96.4344C73.6249 96.1791 73.5087 95.7231 73.1662 95.0544L70.7379 90.2822L71.1477 90.0816L77.8881 93.3462Z"
          fill="#D95A79"
        />
        <path
          d="M69.3494 91.0117C69.0742 91.1029 68.7867 91.2123 68.6766 91.2853C68.4564 91.4251 68.4381 91.6014 68.4992 91.8142L70.01 97.2672C70.0712 97.4861 70.1813 97.6563 70.4443 97.6684C70.6217 97.6745 70.9092 97.632 71.166 97.5833L71.1844 97.6502L67.7408 98.556C66.2973 98.9329 63.7161 98.8539 62.9699 96.1669C62.3338 93.8811 63.9791 92.3491 66.0098 91.8202L69.3189 90.9509L69.3372 91.0178L69.3494 91.0117ZM64.866 95.6805C65.4165 97.6624 66.5542 98.7566 67.8448 98.4162C68.0283 98.3676 68.2852 98.2885 68.4687 98.2338L66.8478 92.3917C66.7316 91.9722 66.3829 91.8385 65.863 91.9783C64.499 92.337 64.3216 93.723 64.866 95.6866V95.6805Z"
          fill="#D95A79"
        />
        <path
          d="M59.0063 93.2732C58.8167 93.2853 58.3396 93.334 58.2112 93.3887C57.9604 93.4859 57.9053 93.6683 57.9053 93.8932L58.0154 99.4861C58.0154 99.711 58.0766 99.8813 58.3396 99.9785C58.5415 100.045 58.9574 100.058 59.1348 100.076V100.149L55.3609 100.222C53.581 100.252 52.6818 99.7232 52.6573 98.5438C52.6268 97.018 54.1314 96.3492 55.7279 96.3188H56.1988L56.1499 93.9419C56.1499 93.7169 56.0582 93.5467 55.8257 93.4495C55.6973 93.4008 55.3119 93.3704 55.0612 93.3522V93.2792L58.9941 93.2063V93.2792L59.0063 93.2732ZM56.0215 96.4465C54.8838 96.4647 54.5107 97.3402 54.529 98.2642C54.5474 99.2916 54.9205 100.1 55.9481 100.082H56.2845L56.2111 96.4465H56.0215Z"
          fill="#D95A79"
        />
        <path
          d="M52.0946 93.3583C51.801 93.334 51.4952 93.3218 51.3668 93.3462C51.1038 93.3887 51.0181 93.5407 50.9875 93.7656L50.1251 99.3463C50.0884 99.5652 50.1251 99.7658 50.3575 99.8813C50.5166 99.9543 50.7979 100.027 51.0548 100.088L51.0426 100.161L45.0361 99.2855L45.3114 97.4861L45.4276 97.5043C45.5132 97.9907 46.0759 99.1944 46.6142 99.3403C46.8589 99.4071 47.1769 99.474 47.495 99.5226L48.3146 99.6442L48.7978 96.5134L48.125 96.4162C47.5562 96.3311 47.3237 96.4648 47.1708 96.5742C46.92 96.7505 46.5836 97.2916 46.5041 97.559L46.3879 97.5408L46.8283 94.6714L46.9445 94.6897C46.9384 95.0058 47.1341 95.6623 47.2809 95.869C47.4032 96.0392 47.599 96.2155 48.1433 96.2946L48.8162 96.3918L49.2076 93.869C49.3177 93.1334 49.1832 92.9936 48.2412 92.8538C47.6724 92.7686 47.1035 92.6896 46.8161 92.8963C46.3328 93.2367 45.605 94.1182 45.3358 94.4587L45.2441 94.4465L45.7028 92.3613L52.1069 93.2915L52.0946 93.3644V93.3583Z"
          fill="#D95A79"
        />
        <path
          d="M43.715 92.6288C44.1431 93.4313 44.2349 94.41 43.8618 95.4496C43.4458 96.5985 42.6874 97.3584 41.7699 97.7232C40.9442 98.0576 39.9961 98.0697 39.0603 97.7475C38.1306 97.4314 37.366 96.8539 36.9256 96.0697C36.473 95.2611 36.3506 94.2581 36.7482 93.1516C37.1336 92.0878 37.8675 91.2732 38.8156 90.8841C39.6352 90.5497 40.5466 90.5437 41.5681 90.8963C42.5161 91.2185 43.2807 91.8264 43.715 92.6288ZM38.6138 93.7535C38.0082 95.4435 37.8003 97.1821 39.1031 97.6259C40.2285 98.0089 41.2316 96.9694 41.9962 94.8599C42.6629 93.0057 42.7302 91.413 41.4947 90.9935C40.2713 90.5741 39.3049 91.8446 38.6199 93.7535H38.6138Z"
          fill="#D95A79"
        />
        <path
          d="M37.4088 89.6135C37.2375 89.5344 36.8033 89.3338 36.6687 89.3156C36.4057 89.273 36.2589 89.4007 36.1427 89.5952L33.2801 94.4464C33.1639 94.641 33.1211 94.8173 33.2985 95.03C33.433 95.1881 33.7878 95.4069 33.9285 95.5103L33.8918 95.5711L30.6194 93.7473C29.0781 92.8902 28.5887 91.9783 29.1882 90.957C29.9711 89.6317 31.6103 89.8141 32.9988 90.5861L33.4086 90.8111L34.6197 88.7563C34.7359 88.5618 34.7481 88.3733 34.6013 88.1727C34.5157 88.0693 34.2037 87.8444 33.9958 87.7046L34.0325 87.6438L37.4455 89.5466L37.4088 89.6074V89.6135ZM33.1762 90.8415C32.1853 90.2882 31.4085 90.8536 30.9375 91.6561C30.4115 92.5497 30.3014 93.4251 31.1944 93.9236L31.4819 94.0878L33.3413 90.9387L33.1762 90.8475V90.8415Z"
          fill="#D95A79"
        />
        <path
          d="M31.5125 86.276C31.3595 86.1848 30.9681 85.893 30.8396 85.8565C30.5766 85.7775 30.4115 85.8808 30.2647 86.0571L26.6192 90.3855C26.4724 90.5557 26.399 90.7259 26.5397 90.963C26.6437 91.1454 26.9556 91.4129 27.0841 91.5406L27.0352 91.5953L24.0013 89.1818L24.0503 89.1271C24.2093 89.2244 24.4784 89.4675 24.668 89.5344C24.9127 89.6256 25.0962 89.5223 25.243 89.352L28.705 85.2364C29.2188 84.6224 29.1392 84.4522 28.4175 83.8747C28.0138 83.5525 27.5979 83.3032 27.3226 83.3276C26.8211 83.3762 25.7017 83.747 25.1635 83.9659L25.0962 83.9172L26.6315 82.3002L31.5614 86.2213L31.5125 86.276Z"
          fill="#D95A79"
        />
        <path
          d="M26.2583 82.0327C26.0504 81.8321 25.8302 81.6314 25.7139 81.5646C25.4876 81.4369 25.3164 81.4916 25.1451 81.6436L20.7718 85.3519C20.6005 85.4978 20.4904 85.6741 20.5944 85.9112C20.6617 86.0693 20.8329 86.3003 20.992 86.507L20.9369 86.5556L16.9428 82.0995L18.3557 80.9019L18.4291 80.9871C18.1722 81.4126 17.7991 82.6831 18.105 83.1391C18.2456 83.3458 18.4414 83.595 18.6554 83.8321L19.1998 84.44L21.6526 82.3609L21.206 81.8625C20.8268 81.443 20.5638 81.3944 20.3742 81.3822C20.0622 81.364 19.4506 81.5585 19.212 81.7105L19.1387 81.6254L21.3834 79.7226L21.4568 79.8077C21.2427 80.0448 20.9491 80.6649 20.9247 80.9202C20.9002 81.1269 20.9369 81.3822 21.2978 81.7895L21.7443 82.288L23.7261 80.6101C24.301 80.1238 24.2949 79.9293 23.671 79.2302C23.2918 78.8107 22.9126 78.3851 22.5639 78.3608C21.9645 78.3122 20.8268 78.525 20.3987 78.6222L20.3375 78.5554L22.0746 77.2605L26.3256 82.0084L26.2705 82.057L26.2583 82.0327Z"
          fill="#D95A79"
        />
        <path
          d="M17.1997 76.3001C15.1935 77.3275 13.0833 76.6831 12.0802 74.8289C11.701 74.1237 11.5419 73.3456 11.5358 72.841L11.0526 72.7559L10.9976 72.6586L13.3463 71.4124L13.4075 71.5218C12.0924 72.8045 11.7438 73.9353 12.1719 74.7256C12.7775 75.8502 14.3678 75.6131 16.1355 74.7134C18.0683 73.7225 18.8879 72.6404 18.2273 71.4124C17.7258 70.4823 16.7655 70.0689 15.6033 70.075V69.9534C16.7899 69.8501 18.0561 70.2574 18.7656 71.5765C19.6892 73.2848 19.2365 75.2666 17.1997 76.3062V76.3001Z"
          fill="#D95A79"
        />
        <path
          d="M15.9275 67.8561C15.5177 68.6707 14.7592 69.3211 13.6827 69.6433C12.4839 70.002 11.4074 69.8561 10.5633 69.3515C9.79872 68.9017 9.22377 68.1661 8.93629 67.242C8.64881 66.3302 8.66716 65.3879 9.05862 64.5794C9.46231 63.7465 10.2146 63.0535 11.3646 62.707C12.4717 62.3787 13.5849 62.4699 14.4718 62.9805C15.2363 63.4243 15.7746 64.1356 16.0927 65.1447C16.3862 66.0809 16.3373 67.0414 15.9275 67.85V67.8561ZM11.9701 64.5246C10.2085 65.0475 8.64881 65.9229 9.0525 67.2056C9.40115 68.312 10.8569 68.4883 13.0588 67.8317C14.9856 67.2542 16.3495 66.3606 15.9642 65.1447C15.585 63.941 13.9641 63.935 11.9762 64.5246H11.9701Z"
          fill="#D95A79"
        />
        <path
          d="M14.0559 61.023C13.5421 61.1081 13.0772 60.7555 12.9977 60.2631C12.9121 59.7707 13.2362 59.2965 13.75 59.2114C14.2638 59.1263 14.7287 59.4789 14.8082 59.9713C14.8938 60.4637 14.5696 60.9379 14.0559 61.023Z"
          fill="#D95A79"
        />
        <mask
          id="mask1_160_235"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="110"
          height="107"
        >
          <path d="M110 0H0V107H110V0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_160_235)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M50.2046 43.1502C49.8866 44.5727 50.8224 45.977 52.2843 46.281C53.7461 46.591 55.1896 45.6852 55.5077 44.2566C55.8258 42.8341 54.896 41.4298 53.4342 41.1258C51.9723 40.8158 50.5288 41.7216 50.2169 43.1441M52.584 43.6487C52.7002 43.1259 53.2323 42.7915 53.7706 42.907C54.3088 43.0225 54.6514 43.5393 54.5352 44.0621C54.4189 44.5849 53.8868 44.9192 53.3486 44.8037C52.8103 44.6882 52.4678 44.1715 52.584 43.6487ZM55.7829 38.2808C53.8807 36.2929 50.6817 36.1774 48.6327 38.0254C47.8314 38.7489 47.2932 39.7094 47.0974 40.755C49.1098 38.1592 52.639 37.15 55.7768 38.2808M20.1418 51.734C20.0195 59.3695 25.2185 66.4639 35.7696 64.6827C51.0243 61.1264 38.1245 46.7187 40.8035 37.4965C34.687 47.4786 48.4431 59.4242 35.5433 62.2328C15.1752 66.6706 20.8269 28.1711 47.0913 30.9249C53.6299 31.6119 56.6454 34.1834 58.1623 38.3051C60.4804 44.5849 60.0584 43.8979 67.4594 46.2992C69.0069 46.6761 70.8297 46.6579 71.7043 47.4847C74.4996 50.0987 71.7594 54.3298 69.5452 56.336C63.2451 62.0444 48.5165 59.8011 45.6417 51.3146C44.8649 55.4484 51.1649 60.8346 52.1375 61.4182C51.7154 61.169 49.4217 62.9076 49.0547 63.1812C46.0026 65.473 44.0453 68.8774 43.6232 72.6039C43.3052 75.6557 44.0514 78.7196 45.7518 81.3033C46.9017 83.0723 48.4981 84.5252 50.382 85.5344C54.4312 87.6743 59.3795 87.5588 63.8568 86.4949C82.4878 81.7166 93.6077 63.1508 88.69 45.0287C83.7784 26.9066 64.6886 16.0917 46.0576 20.8699C31.4635 24.6147 20.9981 37.0649 20.1296 51.7219L20.1418 51.734Z"
            fill="#D95A79"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_160_235">
          <rect width="110" height="107" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BabyToysIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="109"
      viewBox="0 0 88 63"
      fill="none"
    >
      <g clip-path="url(#clip0_32_422)">
        <path
          d="M66.8951 40.5803C69.9703 46.7473 69.5111 52.4975 69.2104 54.7344C71.9201 53.5883 73.3946 52.1295 74.2515 50.9541C74.9759 49.961 75.5094 48.8279 76.7641 48.3948C78.064 47.9455 78.756 49.02 79.642 48.6553C81.2491 47.9943 81.1973 43.8754 80.7252 41.2706C80.3372 39.1216 79.8554 36.4516 77.7083 34.4817C77.7083 34.4817 73.663 30.7763 64.5895 32.1569C64.3761 32.1894 63.6453 32.3099 62.6526 32.2969C61.2557 32.2806 60.1304 32.0103 59.4319 31.8019C61.2525 33.1174 64.5572 35.8883 66.8951 40.577V40.5803Z"
          fill="#CCCCCC"
        />
        <path
          d="M57.6179 32.4988C54.0383 31.5415 50.4619 30.5842 46.8823 29.6237C47.0473 24.2479 45.66 21.6756 44.2793 20.3243C43.6293 19.6862 42.3165 18.667 41.7571 16.8045C41.2882 15.2514 41.7506 13.949 41.6665 13.4638C40.6609 7.63221 34.4653 1.89828 27.5648 0.517715C19.6425 -1.06473 11.4 3.12582 7.26424 9.91471C2.39443 17.9083 4.12441 27.9989 8.70643 33.2802C12.5835 37.7508 18.4105 38.6755 18.0742 40.7008C17.6894 43.0354 9.88669 42.1009 8.7323 45.5588C7.90773 48.0367 10.708 52.1296 13.7508 52.7417C16.0241 53.2008 17.1623 51.4588 20.5996 51.6151C22.8631 51.7193 24.4573 52.5724 25.3368 52.9924C31.2543 55.835 44.1208 58.124 54.5589 57.5444C58.0544 57.349 63.1344 56.7304 69.2071 54.7279C69.2654 52.3738 69.081 47.1185 65.9929 41.5311C63.2508 36.5721 59.5581 33.7686 57.6147 32.4922L57.6179 32.4988Z"
          fill="#F7EBD9"
        />
        <path
          d="M67.7359 23.9125C63.0568 23.9125 58.3325 23.7497 53.6697 23.4274C53.4918 23.4144 53.356 23.2581 53.369 23.079C53.3819 22.8999 53.5403 22.7697 53.7149 22.7762C58.8014 23.1278 63.9655 23.2906 69.0584 23.2581C72.5733 23.2353 76.1335 23.1181 79.642 22.9097C79.8198 22.8999 79.9718 23.0367 79.9847 23.2157C79.9944 23.3948 79.8586 23.5479 79.6808 23.5609C76.1626 23.7693 72.5895 23.8865 69.0649 23.9093C68.6219 23.9093 68.1821 23.9125 67.7391 23.9125H67.7359Z"
          fill="black"
        />
        <path
          d="M55.4967 1.22425C54.6915 1.62149 54.3714 2.12292 53.9607 2.9174C53.4336 4.00167 53.0197 5.20316 52.6187 6.36232C51.7715 8.93135 50.8338 11.7413 50.1612 14.4634C49.4595 17.3743 50.0771 19.0089 52.276 20.6467C54.7303 22.4928 57.6179 23.0757 60.942 23.3622C65.9574 23.7529 70.8886 23.9516 75.9201 22.7078C79.9847 21.7602 84.6541 19.7578 85.456 15.1667C85.595 13.9913 84.9871 12.9526 84.3695 11.9595C83.4253 10.3412 82.3356 8.58621 82.3065 6.80514C82.2741 5.89995 82.5684 4.41845 83.034 3.2137C83.2119 2.63087 83.5191 2.15874 83.3444 1.68987C83.2345 1.52055 82.9467 1.51078 82.5587 1.66382C81.6985 1.96989 80.9807 2.89135 80.1205 3.19091C79.0761 3.36999 77.9088 2.42574 76.6153 2.76762C75.8457 2.86205 73.5272 4.09284 75.3413 4.51287C77.4043 4.93942 79.6096 6.18649 80.2143 8.31595C80.6379 9.60861 80.7673 11.4157 79.878 12.614C78.8174 14.0727 76.6606 14.3853 75.1246 13.5615C73.6566 12.8321 72.5248 11.3376 71.5062 9.34487C70.0123 6.5707 69.275 2.90438 66.3713 1.26658C63.6583 -0.351685 58.3196 0.0325306 55.5516 1.20146L55.5031 1.22751L55.4967 1.22425Z"
          fill="#7B98B7"
        />
        <path
          d="M59.0051 12.7149C58.9114 13.0503 58.5654 13.2489 58.2323 13.1545C57.8992 13.0601 57.702 12.7117 57.7958 12.3763C57.8895 12.0409 58.2355 11.8423 58.5686 11.9367C58.9016 12.0312 59.0989 12.3796 59.0051 12.7149Z"
          fill="black"
        />
        <path
          d="M52.4506 18.8396C51.9106 18.8396 51.3674 18.7875 50.8306 18.6801C50.656 18.6442 50.5396 18.4749 50.5751 18.2991C50.6075 18.1233 50.7788 18.006 50.9535 18.0419C51.7457 18.1982 52.5476 18.2275 53.3366 18.1363C55.3512 17.8986 56.8354 16.912 57.7279 16.124C57.8604 16.0068 58.0674 16.0198 58.1838 16.1533C58.3002 16.2901 58.2873 16.4952 58.1547 16.6125C57.1943 17.459 55.5904 18.5238 53.4142 18.781C53.0941 18.8201 52.774 18.8363 52.4539 18.8363L52.4506 18.8396Z"
          fill="black"
        />
        <path
          d="M79.2152 21.5389C79.0632 21.5389 78.9274 21.4314 78.8983 21.2751C78.8627 21.0993 78.9791 20.9267 79.1537 20.8942C81.7568 20.383 83.6258 19.035 84.4083 17.0976C85.2652 14.9812 84.7964 12.3698 83.1278 9.93427L83.0987 9.88217C82.0704 7.58665 82.0607 4.94598 83.0728 2.45509C83.144 2.23368 83.1699 2.09367 83.1796 2.01227C83.0599 2.04808 82.8336 2.14902 82.4003 2.39974C81.6695 2.79698 80.7802 3.2105 79.6646 3.3212C79.0696 3.37656 78.549 3.3212 78.0478 3.26259C77.4205 3.19096 76.8288 3.12258 76.1626 3.30818C75.3639 3.53936 74.9791 3.85194 74.9597 3.95939C74.9597 3.95939 75.0729 4.14499 75.8231 4.31756C78.0414 4.82876 79.7067 6.41772 80.2822 8.56998C80.7673 10.3934 80.3566 12.2493 79.2346 13.301C78.2127 14.2583 76.7123 14.4602 75.0147 13.8676C74.8466 13.809 74.756 13.6234 74.8142 13.4541C74.8724 13.2848 75.0567 13.1936 75.2249 13.2522C76.7059 13.7699 77.9411 13.6201 78.7916 12.8224C79.7422 11.9335 80.0818 10.3282 79.6549 8.73603C79.144 6.82147 77.6566 5.40834 75.6776 4.94923C75.3445 4.87109 74.3324 4.63665 74.313 3.96916C74.2936 3.24631 75.4771 2.82303 75.988 2.6765C76.777 2.45835 77.4593 2.53649 78.1222 2.61138C78.5911 2.66348 79.0761 2.71883 79.6032 2.66999C80.6024 2.57231 81.4205 2.18809 82.0866 1.82667C82.7463 1.44571 83.283 1.17546 83.629 1.45548C83.9394 1.70945 83.836 2.19135 83.6775 2.6765C82.7301 5.01436 82.7333 7.45966 83.6775 9.58587C85.4625 12.2005 85.9475 15.0235 85.0098 17.3386C84.1399 19.4876 82.106 20.9756 79.2798 21.5291C79.2604 21.5324 79.2378 21.5356 79.2184 21.5356L79.2152 21.5389Z"
          fill="black"
        />
        <path
          d="M28.606 42.5567C25.1331 42.5567 22.4072 42.0585 21.7669 41.9316C21.505 41.8795 21.3336 41.6222 21.3854 41.3585C21.4371 41.0947 21.6926 40.9222 21.9545 40.9743C23.4516 41.2738 36.7644 43.6735 43.3512 36.3702C44.0788 35.5627 45.7861 33.6709 46.4846 30.607C47.5808 25.8206 45.3496 21.7114 44.3375 20.1648C44.1887 19.9401 44.2534 19.6373 44.4765 19.4875C44.6997 19.341 45.0004 19.4029 45.1491 19.6275C46.2291 21.2783 48.6123 25.674 47.4321 30.8251C46.6722 34.1365 44.7837 36.2334 44.0723 37.0247C39.9948 41.5473 33.5502 42.5567 28.6092 42.5567H28.606Z"
          fill="black"
        />
        <path
          d="M33.9124 27.3021C33.893 27.3021 33.8703 27.3021 33.8509 27.2988C33.5858 27.2663 33.3982 27.0188 33.4306 26.7518C33.4694 26.436 33.6828 25.3452 34.7208 24.6321C35.7911 23.8963 36.9067 24.1209 37.2236 24.2056C37.4823 24.2772 37.6342 24.5442 37.5663 24.8047C37.4952 25.0652 37.23 25.2182 36.9714 25.1498C36.8194 25.1075 36.0142 24.9252 35.2673 25.4396C34.5462 25.9345 34.4136 26.7225 34.3942 26.8755C34.3618 27.123 34.1549 27.3021 33.9124 27.3021Z"
          fill="black"
        />
        <path
          d="M8.12113 36.4744C7.14458 36.4744 5.50838 36.2433 4.36045 34.8692C2.77275 32.9742 2.97324 29.8549 4.78729 28.1975C6.61427 26.5272 9.08474 27.1133 9.36283 27.1881C9.62152 27.2565 9.77674 27.5235 9.70883 27.784C9.64093 28.0445 9.37577 28.2008 9.11708 28.1324C9.02654 28.1096 6.915 27.5724 5.44047 28.9204C3.99505 30.2423 3.83661 32.7267 5.10418 34.2408C6.33618 35.7125 8.33778 35.5139 8.72905 35.4618C11.1349 35.1264 12.2019 32.8244 12.244 32.7267C12.3539 32.4825 12.6417 32.3718 12.8875 32.4858C13.13 32.5965 13.2399 32.8863 13.1268 33.1337C12.9942 33.43 11.7395 36.0284 8.86163 36.4321C8.73552 36.4484 8.47036 36.481 8.12113 36.481V36.4744Z"
          fill="black"
        />
        <path
          d="M10.1324 32.5574C9.97399 32.5574 9.81878 32.4792 9.72824 32.336C9.61506 32.1634 9.28847 31.7434 8.70319 31.6001C7.96916 31.4243 7.38711 31.8215 7.27717 31.9029C7.06375 32.0657 6.75979 32.0201 6.59811 31.802C6.43966 31.5838 6.4817 31.2778 6.69835 31.1182C6.93764 30.9391 7.80424 30.3758 8.92954 30.6493C9.85435 30.874 10.362 31.5285 10.5399 31.7987C10.6886 32.0234 10.6239 32.3262 10.4008 32.476C10.32 32.5313 10.2262 32.5574 10.1357 32.5574H10.1324Z"
          fill="black"
        />
        <path
          d="M69.2556 54.9102C69.2556 54.9102 69.2459 54.9102 69.2427 54.9102C68.9743 54.9037 68.7641 54.679 68.7706 54.4088C68.8935 49.9708 67.9751 45.5132 66.1158 41.518C64.4699 37.9852 62.0479 34.7519 59.1151 32.1731C58.9146 31.9973 58.8919 31.688 59.0698 31.4828C59.2444 31.281 59.5516 31.2582 59.7553 31.4373C62.7917 34.104 65.2945 37.4479 66.9986 41.1012C68.9226 45.2332 69.8733 49.847 69.7471 54.4348C69.7407 54.6986 69.524 54.9102 69.2621 54.9102H69.2556Z"
          fill="black"
        />
        <path
          d="M72.0107 41.1892C71.8684 41.1892 71.7294 41.1273 71.6324 41.0069C69.33 38.1448 65.3204 34.3059 59.2768 32.2676C56.4991 31.3298 53.5759 30.8772 50.5978 30.9098H50.5913C50.3261 30.9098 50.1095 30.6949 50.1063 30.4279C50.1063 30.1577 50.3164 29.9362 50.5848 29.933C53.6762 29.8972 56.7028 30.3693 59.584 31.3396C65.8474 33.4528 70.0026 37.4284 72.3858 40.3882C72.5539 40.5966 72.5216 40.9059 72.3147 41.0752C72.2241 41.1469 72.1174 41.1827 72.0107 41.1827V41.1892Z"
          fill="black"
        />
        <path
          d="M56.9841 58.1468C55.6228 58.1468 54.3908 58.0426 53.3366 57.8993C53.0715 57.8635 52.8839 57.616 52.9227 57.349C52.9583 57.082 53.2073 56.8964 53.4692 56.9322C56.7545 57.3848 61.8087 57.4206 67.2282 55.0112C70.7334 53.4548 73.9023 51.0583 76.3954 48.0823C76.5668 47.8771 76.874 47.8511 77.0777 48.0237C77.2815 48.1962 77.3073 48.5056 77.1359 48.7107C74.5491 51.8007 71.2573 54.2883 67.6194 55.9033C63.7327 57.629 60.0269 58.1468 56.9841 58.1468Z"
          fill="black"
        />
        <path
          d="M84.9192 45.0118C84.7026 45.0118 84.5086 44.8685 84.4504 44.6471C83.9589 42.7684 82.2903 38.0536 77.4431 34.892C72.4278 31.6196 67.322 32.1373 65.3559 32.5085C65.094 32.5606 64.8386 32.3848 64.7901 32.1178C64.7416 31.8541 64.9129 31.5968 65.1781 31.548C67.2573 31.1507 72.6639 30.607 77.9735 34.0714C83.1052 37.4187 84.8707 42.407 85.3914 44.3964C85.4593 44.6569 85.304 44.9239 85.0454 44.9923C85.0033 45.002 84.9613 45.0086 84.9225 45.0086L84.9192 45.0118Z"
          fill="black"
        />
        <path
          d="M79.5482 49.3717C78.6363 49.3717 77.6339 49.2317 76.5992 48.854C75.5547 48.473 74.5943 47.8869 73.7439 47.1185C73.5434 46.9394 73.5272 46.6301 73.7083 46.4282C73.8894 46.2263 74.1934 46.2101 74.3938 46.3924C75.1505 47.0762 76.0042 47.5971 76.929 47.9358C79.3833 48.8344 81.6371 48.2158 82.7624 47.7665C83.0114 47.6655 83.2927 47.7892 83.393 48.04C83.49 48.2907 83.3703 48.574 83.1213 48.6749C82.3388 48.9875 81.0648 49.3717 79.5482 49.3717Z"
          fill="black"
        />
        <path
          d="M79.642 49.1438C79.5482 49.1438 79.4512 49.1144 79.3671 49.0591C79.1472 48.9061 79.0923 48.6032 79.2442 48.3786C79.8101 47.5515 80.515 46.1873 80.6606 44.3801C80.8158 42.446 80.2596 40.8994 79.7681 39.9421C79.6452 39.7012 79.739 39.4081 79.975 39.2844C80.2143 39.1607 80.5053 39.2551 80.6282 39.4928C81.1779 40.5608 81.7988 42.2897 81.6242 44.4583C81.4625 46.477 80.6735 48.0041 80.0429 48.9321C79.9492 49.0689 79.7972 49.1438 79.642 49.1438Z"
          fill="black"
        />
        <path
          d="M90.4034 49.9383C89.1261 49.9383 88.0849 49.2805 87.5417 48.8475C87.3315 48.6814 87.2959 48.3721 87.4608 48.1604C87.6258 47.9488 87.9329 47.913 88.1431 48.079C88.6476 48.4795 89.6694 49.121 90.8529 48.9256C91.6548 48.7921 91.9976 48.5381 92.0267 48.3981C92.0849 48.1539 91.6322 47.4995 90.5295 47.0729L89.9636 46.8548L90.2999 46.3468C90.7914 45.6012 90.8723 44.963 90.8497 44.556C90.7688 42.9898 89.1585 41.5278 87.0211 41.0753C86.7591 41.0199 86.591 40.7627 86.646 40.4957C86.7009 40.2319 86.9596 40.0626 87.2216 40.118C89.8246 40.6682 91.7163 42.4688 91.8197 44.5039C91.8521 45.129 91.7066 45.7737 91.3961 46.3924C92.5182 46.972 93.152 47.8414 92.9709 48.6196C92.8222 49.2577 92.1431 49.6973 91.0113 49.8862C90.8044 49.922 90.6007 49.935 90.4034 49.935V49.9383Z"
          fill="black"
        />
        <path
          d="M1.66689 55.0698C1.64102 55.0698 1.61191 55.0698 1.58605 55.0633C1.32089 55.0177 1.14304 54.7669 1.18831 54.5C1.44377 52.9729 2.23923 50.0457 4.70324 47.6329C5.8059 46.5519 7.09934 45.7053 8.55123 45.1095C8.80022 45.0053 9.08154 45.129 9.18501 45.3765C9.28526 45.6272 9.16561 45.9105 8.91986 46.0147C7.58438 46.5617 6.39441 47.3431 5.3823 48.333C3.11554 50.5536 2.38475 53.2529 2.14869 54.6628C2.10989 54.9004 1.90294 55.0698 1.67012 55.0698H1.66689Z"
          fill="black"
        />
        <path
          d="M4.67088 60.6441C4.59327 60.6441 4.52213 60.6441 4.45746 60.6441C4.18907 60.6376 3.97889 60.413 3.98212 60.146C3.98536 59.879 4.21494 59.6608 4.47686 59.6673C5.66036 59.6966 8.67408 59.5078 11.2416 57.1829C13.2141 55.3986 13.9416 53.2171 14.2003 52.0774C14.2585 51.8137 14.5172 51.6509 14.7824 51.7095C15.0443 51.7681 15.206 52.0319 15.1478 52.2956C14.8632 53.5492 14.0645 55.9456 11.8915 57.9123C9.21086 60.3381 6.0872 60.6474 4.67088 60.6474V60.6441Z"
          fill="black"
        />
        <path
          d="M-4.16008 61.4907C-4.50285 61.4907 -4.81327 61.4126 -5.08489 61.253C-5.69605 60.8916 -5.95473 60.1981 -5.99354 59.7129C-6.20695 57.0071 -0.302395 54.7507 1.51166 54.1222C1.76388 54.0343 2.04197 54.1711 2.12927 54.4251C2.21658 54.679 2.08077 54.9591 1.82855 55.047C-1.5538 56.2159 -5.1431 58.1533 -5.02346 59.638C-5.00405 59.9018 -4.85854 60.2567 -4.59015 60.413C-3.92726 60.8037 -2.20375 60.0646 -0.198919 57.8895C-0.0146041 57.6909 0.289354 57.6811 0.486604 57.8635C0.683854 58.0458 0.693555 58.3551 0.512473 58.5538C-1.24984 60.4618 -2.91515 61.494 -4.15685 61.494L-4.16008 61.4907Z"
          fill="black"
        />
        <path
          d="M2.03227 61.5982C1.91263 61.5982 1.79298 61.5917 1.67334 61.5786C0.939312 61.5005 0.324927 61.1912 -0.0534043 60.7027C-0.784199 59.7617 -0.431736 58.443 -0.305625 58.0621C-0.221551 57.8049 0.0500713 57.6681 0.30876 57.7528C0.564214 57.8374 0.700025 58.1142 0.615952 58.3714C0.577148 58.4886 0.257022 59.5208 0.71296 60.1036C0.926378 60.3771 1.31441 60.5627 1.77682 60.6116C2.56581 60.693 3.43242 60.3934 4.15675 59.7813C4.3637 59.6087 4.66766 59.6348 4.83904 59.8431C5.01042 60.0483 4.98455 60.3576 4.7776 60.5302C3.9595 61.2205 2.97648 61.6014 2.03227 61.6014V61.5982Z"
          fill="black"
        />
        <path
          d="M27.29 62.9983C26.7338 62.9983 26.2132 62.8322 25.809 62.5001C25.1687 61.9759 24.8324 61.0284 25.0232 60.299C25.4015 58.8728 27.8267 58.1109 31.5227 58.2542C31.7911 58.264 31.9981 58.4919 31.9884 58.7621C31.9787 59.0324 31.762 59.2473 31.4839 59.231C28.1242 59.0975 26.1614 59.7943 25.9609 60.5497C25.8769 60.8753 26.0385 61.4321 26.4201 61.7447C26.8825 62.1224 27.6359 62.1224 28.3441 61.7447C28.5802 61.6177 28.8744 61.7089 29.0005 61.9466C29.1266 62.1843 29.0361 62.4806 28.8 62.6075C28.3053 62.8745 27.7815 63.0048 27.29 63.0048V62.9983Z"
          fill="black"
        />
        <path
          d="M35.4645 67C35.0862 67 34.7078 66.974 34.3295 66.9186C30.7596 66.3944 28.703 63.3435 28.1565 62.422C28.0207 62.1908 28.0951 61.8913 28.3247 61.7512C28.5543 61.6145 28.8518 61.6894 28.9908 61.9206C29.4823 62.7476 31.3255 65.4892 34.4718 65.9516C36.9843 66.3228 39.5679 65.1734 40.9066 63.9523C41.631 63.2914 42.0287 62.5913 42.0028 62.0248C41.9802 61.5136 41.5954 60.9796 40.8614 60.439C40.6447 60.2795 40.5962 59.9734 40.7547 59.7553C40.9131 59.5371 41.2171 59.4883 41.4337 59.6478C42.4167 60.3707 42.9341 61.1521 42.9697 61.9759C43.0085 62.8453 42.5073 63.8058 41.5566 64.6719C40.2114 65.8995 37.9026 66.9935 35.4645 66.9968V67Z"
          fill="black"
        />
        <path
          d="M46.6916 63.77C45.1653 63.77 43.6035 63.3141 42.2195 62.409C41.9964 62.2624 41.9317 61.9596 42.0772 61.7317C42.2227 61.5038 42.5234 61.4419 42.7498 61.5884C45.0845 63.1155 47.9785 63.1969 50.1224 61.7903C52.2631 60.3869 52.8063 58.023 52.9227 57.3328C52.968 57.0658 53.2202 56.8867 53.4821 56.9323C53.7473 56.9778 53.9251 57.2286 53.8799 57.4956C53.7473 58.2868 53.1264 60.9893 50.6527 62.6108C49.4725 63.3858 48.0982 63.77 46.6948 63.77H46.6916Z"
          fill="black"
        />
        <path
          d="M38.5364 58.6449C38.5364 58.6449 38.517 58.6449 38.5105 58.6449C38.2421 58.6286 38.0384 58.4007 38.0514 58.1305C38.0805 57.616 38.4426 53.0315 42.4361 50.5927C45.2558 48.8702 48.1402 49.2772 49.2493 49.5345C49.5113 49.5931 49.6729 49.8568 49.6147 50.1206C49.5565 50.3843 49.2946 50.5504 49.0327 50.4885C48.04 50.2606 45.4563 49.8926 42.9406 51.4295C39.3707 53.6078 39.0473 57.7235 39.0214 58.1858C39.0085 58.4463 38.7919 58.6482 38.5364 58.6482V58.6449Z"
          fill="black"
        />
        <path
          d="M38.5332 58.645C38.4297 58.645 38.3262 58.6124 38.2389 58.544C37.5502 58.0133 36.0821 57.1211 34.1193 57.1602C32.6351 57.183 31.4742 57.7105 30.7661 58.15C30.5397 58.2901 30.239 58.2217 30.1 57.9905C29.9609 57.7593 30.0321 57.4598 30.2584 57.3197C31.0733 56.8151 32.399 56.2094 34.1064 56.1834C36.3667 56.1508 38.0385 57.1602 38.8275 57.7658C39.0409 57.9286 39.0829 58.2347 38.918 58.4496C38.8242 58.5766 38.6787 58.6417 38.5332 58.6417V58.645Z"
          fill="black"
        />
        <path
          d="M36.3667 33.6937C35.361 33.6937 34.5558 33.4593 34.1419 33.3063C33.8897 33.2151 33.7604 32.9351 33.8509 32.6811C33.9415 32.4271 34.2228 32.2969 34.4718 32.388C35.0765 32.6095 36.6771 33.0458 38.42 32.2969C40.5898 31.3656 41.3238 29.2818 41.4499 28.8715C41.5307 28.6143 41.7991 28.471 42.0578 28.5491C42.3133 28.6306 42.4588 28.9008 42.3779 29.1613C42.171 29.8353 41.3011 32.1243 38.8016 33.1955C37.935 33.5667 37.0975 33.6937 36.3634 33.6937H36.3667Z"
          fill="black"
        />
        <path
          d="M26.1609 24.3112C26.6719 24.1552 26.9328 23.5195 26.7436 22.8914C26.5544 22.2632 25.9868 21.8805 25.4758 22.0366C24.9648 22.1926 24.7039 22.8283 24.8931 23.4564C25.0823 24.0845 25.6499 24.4672 26.1609 24.3112Z"
          fill="black"
        />
        <path
          d="M38.7803 19.7389C39.2264 19.6027 39.4346 18.9831 39.2454 18.355C39.0563 17.7268 38.5413 17.328 38.0953 17.4643C37.6492 17.6005 37.441 18.2201 37.6301 18.8482C37.8193 19.4763 38.3342 19.8751 38.7803 19.7389Z"
          fill="black"
        />
        <path
          d="M27.3384 53.5753C27.3384 53.5753 27.319 53.5753 27.3093 53.5753C25.6796 53.4776 24.1016 53.0771 22.6239 52.3901C20.9909 51.6281 19.497 50.5048 18.3038 49.1372C18.1259 48.9354 18.1453 48.626 18.349 48.447C18.5495 48.2679 18.8567 48.2907 19.0346 48.4925C20.1372 49.7592 21.5212 50.8011 23.0313 51.5044C24.3991 52.1426 25.8575 52.5105 27.3675 52.6017C27.6359 52.618 27.8397 52.8492 27.8235 53.1194C27.8073 53.3799 27.5939 53.5785 27.3384 53.5785V53.5753Z"
          fill="black"
        />
        <path
          d="M21.4145 51.7844C21.4145 51.7844 21.3854 51.7844 21.3692 51.7844C19.8397 51.6411 18.3587 51.1462 17.0815 50.3583C14.3911 48.6944 13.2367 46.184 12.7905 44.836C12.7064 44.5787 12.8422 44.302 13.0977 44.2173C13.3531 44.1294 13.628 44.2694 13.7121 44.5267C14.1195 45.7509 15.1639 48.0269 17.5924 49.528C18.7435 50.241 20.0823 50.6839 21.463 50.8141C21.7282 50.8402 21.9254 51.0779 21.8995 51.3448C21.8769 51.5988 21.6635 51.7877 21.4177 51.7877L21.4145 51.7844Z"
          fill="black"
        />
        <path
          d="M18.4072 52.4454C17.754 52.4454 17.0426 52.3575 16.3021 52.1263C14.7824 51.6509 13.7314 50.762 13.117 50.0978C12.936 49.8992 12.9457 49.5931 13.1429 49.4075C13.3402 49.2219 13.6441 49.2349 13.8284 49.4335C14.362 50.0099 15.2739 50.7815 16.5899 51.1951C18.6853 51.8495 20.522 51.1755 21.214 50.8564C21.4597 50.7457 21.7443 50.8532 21.8575 51.0974C21.9707 51.3416 21.8639 51.6314 21.6182 51.7453C21.0685 51.9993 19.885 52.4487 18.4072 52.4487V52.4454Z"
          fill="black"
        />
        <path
          d="M23.4031 4.71153C23.2512 4.71153 23.1024 4.6399 23.0087 4.5064C22.585 3.91054 21.8381 3.09653 20.6869 2.59184C18.4881 1.62804 16.3895 2.46485 15.7977 2.74813C15.5552 2.86209 15.2674 2.75789 15.151 2.51369C15.0378 2.26949 15.1413 1.9797 15.3838 1.86248C16.0693 1.53687 18.5042 0.566565 21.075 1.69642C22.4298 2.29228 23.3061 3.24305 23.7977 3.93985C23.9529 4.158 23.9044 4.46407 23.6877 4.62036C23.6036 4.68223 23.5034 4.71153 23.4064 4.71153H23.4031Z"
          fill="black"
        />
        <path
          d="M26.2487 2.99884C26.1226 2.99884 25.9933 2.95 25.8995 2.84906C25.2528 2.1718 24.4508 1.64432 23.5842 1.33174C22.0677 0.78472 20.6934 0.996363 20.0111 1.17219C19.7492 1.24057 19.4873 1.08102 19.4226 0.820536C19.3547 0.560051 19.5131 0.293054 19.7718 0.227933C20.7387 -0.0195279 22.2423 -0.188843 23.9141 0.413528C24.923 0.778207 25.851 1.38709 26.6012 2.17506C26.7855 2.37042 26.779 2.67975 26.5883 2.86534C26.4945 2.95651 26.3716 3.0021 26.252 3.0021L26.2487 2.99884Z"
          fill="black"
        />
        <path
          d="M21.6473 23.2808C21.3854 23.2808 21.172 23.0724 21.1623 22.8087C21.1429 22.2519 21.2205 21.4021 21.6926 20.5229C22.2779 19.4256 23.1768 18.8591 23.6586 18.6181C23.8979 18.4977 24.1889 18.5986 24.3086 18.8396C24.4282 19.0805 24.328 19.3735 24.0887 19.494C23.7104 19.6829 23.0087 20.1257 22.5463 20.9853C22.1776 21.6723 22.1194 22.3366 22.1324 22.7729C22.1421 23.0431 21.9319 23.2678 21.6635 23.2776C21.657 23.2776 21.6538 23.2776 21.6473 23.2776V23.2808Z"
          fill="black"
        />
        <path
          d="M39.4451 14.8639C39.3448 14.8639 39.2446 14.8314 39.1573 14.7695C38.8177 14.5155 38.4459 14.3397 38.0482 14.2388C37.1007 14.0043 36.2794 14.2876 35.8752 14.4797C35.6326 14.5937 35.3449 14.4895 35.2284 14.2453C35.1153 14.0011 35.2187 13.7113 35.4613 13.5941C35.9819 13.3499 37.0393 12.9819 38.2777 13.2912C38.7983 13.4215 39.2866 13.6527 39.7296 13.9848C39.9463 14.1443 39.9915 14.4504 39.8299 14.6686C39.7361 14.7988 39.5873 14.8672 39.4386 14.8672L39.4451 14.8639Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_32_422">
          <rect width="99" height="67" fill="white" transform="translate(-6)" />
        </clipPath>
      </defs>
    </svg>
  );
};
